<template>
<!--  <page-header-wrapper>-->
    <a-modal
        :visible="visible"
             width="60%"
             :footer="null"
             @cancel="cancel">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <a-col :md="8" :sm="24">
              <a-form-item label="用户名" prop="inviteCode">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户名" allow-clear/>
              </a-form-item>
            </a-col>

<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="时间筛选">-->
<!--                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->


            <template v-if="advanced">


<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="手机号" prop="userPhone">-->
<!--                  <a-input v-model="queryParam.userPhone" placeholder="请输入手机号" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->

<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="邮箱" prop="userEmail">-->
<!--                  <a-input v-model="queryParam.userEmail" placeholder="请输入邮箱" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:distributor:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:distributor:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:distributor:remove']">-->
        <!--          <a-icon type="delete" />删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:distributor:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
            :style="{float: 'right'}"
            :table-size.sync="tableSize"
            v-model="columns"
            :refresh-loading="loading"
            @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
          ref="createForm"
          @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
          :bordered="tableBordered"
          :rowKey="(record, index) => index + 1"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="status" slot-scope="text, record">
          <a-switch checkedChildren="启用" unCheckedChildren="封禁" v-model="record.status == 0" @change="changeIsOpen($event,record)"/>
        </span>


        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.DistributorIndex.getDatas(record.userId)">
            <a-icon type="edit" />下级用户
          </a>
<!--          <a-divider type="vertical"  />-->
<!--           <a @click="$refs.DistributorUserIndex.getUserDatas(record.id)" >-->
<!--            <a-icon type="search" />二级分销商-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <div style="padding-top: 15px;display: flex;align-items: center;justify-content: flex-end">
      <a-pagination
          style="margin-right: 5%"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"

      />
      </div>
<!--     <second-distributor-index ref="SecondDistributorIndex"></second-distributor-index>-->
<!--      <second-distributor-user-index ref="SecondDistributorUserIndex"></second-distributor-user-index>-->
    </a-modal>

<!--  </page-header-wrapper>-->
</template>

<script>
import { pageSecondDistributorData} from '@/api/user/distributorData'
import { tableMixin } from '@/store/table-mixin'


export default {
  name: 'UserData',
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
      dateRange: [],
      userId: "",
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        inviteUrl: null,
        inviteCode: null,
        userEmail: null,
        userPhone: null,
        distributorRait: null,
        bankName: null,
        bankNo: null,
        realName: null,
        bankAddress: null,
        status: null,
        address: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          // ellipsis: true,,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        // {
        //   title: '用户ID',
        //   dataIndex: 'id',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '用户名',
          dataIndex: 'nickname',
          // ellipsis: true,,
          align: 'center'
        },
        // {
        //   title: '邮箱',
        //   dataIndex: 'userEmail',
        //   width: 200,
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        //
        // {
        //   title: '手机号',
        //   dataIndex: 'userPhone',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '购买商品金额数($)',
          dataIndex: 'goodsMoney',
          align: 'center'
        },
        {
          title: '购买会员金额数($)',
          dataIndex: 'vipMoney',
          align: 'center'
        },
        {
          title: '充值金额($)',
          dataIndex: 'payNum',
          // ellipsis: true,,
          align: 'center'
        },
        {
          title: '从此用户处获得分佣金额($)',
          dataIndex: 'totalMoney',
          // ellipsis: true,,
          align: 'center'
        },

        //
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {

    cancel(){
      this.visible=false
    },
    /** 查询分销商列表 */

    getList (userId) {
      if (!this.userId) {
        this.userId=userId
      }
      if (this.dateRange) {
      this.queryParam.begTime = this.dateRange[0]
      this.queryParam.endTime = this.dateRange[1]
    }
      this.queryParam.userId=this.userId
      this.visible = true
      pageSecondDistributorData(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
      })

    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },

    changeIsOpen (e,record) {
      record.status = e ? 0 : 1
      changeStatus(record).then(response => {
        this.$message.success(
            '操作成功'
        )
        return  record.status = e ? 0 : 1
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        userId: undefined,
        inviteUrl: undefined,
        inviteCode: undefined,
        userEmail: undefined,
        userPhone: undefined,
        distributorRait: undefined,
        bankName: undefined,
        bankNo: undefined,
        realName: undefined,
        bankAddress: undefined,
        nickname: undefined,
        status: undefined,
        address: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.queryParam.pageNum = 1
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        //content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDistributor(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                    '删除成功',
                    3
                )
              })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-distributor/export', {
            ...that.queryParam
          }, `分销商_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
